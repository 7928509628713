import React, { useReducer } from "react";
import { clearLocalStorage } from "../../services/auth/auth.service";
import AuthContext from "./AuthContext";
import { ACTIONS } from "./Constants";
import { AppRoles, CustomManagementRole } from "../../enums/role.enum";

const AuthProvider = (props: any) => {
    const [state, dispatch] = useReducer(
        (prevState: any, action: any) => {
            switch (action.type) {
                case ACTIONS.SET_TOKEN:
                    return {
                        ...prevState,
                        token: action.token,
                        isLoading: false,
                    };
                case ACTIONS.LOGIN:
                    localStorage.setItem("token", JSON.stringify(action.token));
                    return {
                        ...prevState,
                        token: action.token,
                        isLoading: false,
                    };
                case ACTIONS.SET_USER:
                    let group_role = null;

                    if(action.user?.role === AppRoles.CLINICIAN){
                        if(!!action.user?.organization){
                            if(action.user?.id === action.user.organization?.owner?.id){
                                group_role = CustomManagementRole.B2B_ACCOUNT_OWNER;
                            }else{
                                group_role = CustomManagementRole.B2B_CLINICIAN;
                            }
                        }else{
                            group_role = CustomManagementRole.B2C_CLINICIAN;
                        }
                    }
                    if(action.user?.role === AppRoles.PARENT){
                        if(!!action.user?.organization){
                            group_role = CustomManagementRole.B2B_STUDENT;
                        }else{
                            group_role = CustomManagementRole.B2C_STUDENT;
                        }
                    }

                    if(action.user?.role === AppRoles.ADMIN){
                        group_role = AppRoles.ADMIN;
                    }

                    localStorage.setItem("user", JSON.stringify({ ...action.user, group_role, students: prevState?.user?.students || [] }));

                    return {
                        ...prevState,
                        user: { ...action.user, group_role, students: prevState?.user?.students || [] },
                    };
                case ACTIONS.SET_EMAIL_STRIPE_CHECKOUT:
                    return {
                        ...prevState,
                        emailForStripeCheckout:
                            action.payload.emailForStripeCheckout,
                    };
                case ACTIONS.SET_STUDENTS_UNDER_USER:
                    return {
                        ...prevState,
                        user: { ...prevState.user, students: action.students },
                    };
                case ACTIONS.LOGOUT:
                    console.log('LOGOUT TRIGGERED')
                    clearLocalStorage();
                    window.parent?.postMessage('AHA_WEB_APP_LOGOUT', '*');
                    return {
                        ...prevState,
                        token: null,
                        isLoading: false,
                        user: null,
                        avoidGameSetting: true,
                    };
                case ACTIONS.SET_LOADING:
                    return {
                        ...prevState,
                        isLoading: action.isLoading,
                    };
                default:
                    throw new Error("Undefined action provided.");
            }
        },
        {
            user: null,
            isLoading: true,
            token: null,
            avoidGameSetting: false,
            triggerAdminLogout: false,
        }
    );

    return (
        <AuthContext.Provider value={{ state, dispatch }}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
